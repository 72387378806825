import {DatasetStepData, DataSourceType, PipelineVersion, StepType, TemporaryPipelineRequest} from "./models";
import {v4 as uuidv4} from 'uuid'

export const tempChatPipelineBody = (userInput: string) => ({
    "pipeline": {
        "id": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
        "description": "Temporary Pipeline for Playground",
        "name": "Temporary Playground Pipeline",
        "executionName": "temporary_playground_pipeline",
        "createdAt": "2024-08-28T13:58:21.413Z",
        "updatedAt": "2024-08-28T13:58:21.413Z",
        "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
        "activeVersionId": "533abc28-0ea9-438f-bb85-178bc2ccf170",
        "activeVersion": {
            "id": "533abc28-0ea9-438f-bb85-178bc2ccf170",
            "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
            "versionNumber": 1,
            "isLatest": true,
            "steps": [{
                "modelId": "75698c6a-2408-4e62-a113-fd464e197242",
                "temperature": 0.5,
                "includeDateTimeContext": true,
                "promptId": "d3676ce1-e93c-4778-9fbe-b1a251e3edd0",
                "selectedPromptVersion": null,
                "stepType": "AIOperation",
                "stepId": "08919212-59de-4ec5-a452-d6f6de4131f6",
                "position": {
                    "id": "7e95c08c-6d50-496a-a73c-41d95516f8b1",
                    "x": 761,
                    "y": 257,
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "createdAt": "2024-08-05T18:49:22.108125Z",
                    "updatedAt": "2024-08-05T18:49:22.108125Z",
                    "userId": null
                },
                "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
                "pipelineVersionId": "06da5413-b0c5-4f26-9014-2d6c40ec382e",
                "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
                "createdAt": "2024-06-03T14:12:36.7039Z",
                "updatedAt": "2024-06-07T23:30:14.902254Z",
                "userId": null
            }, {
                "stepType": "inputStep",
                "stepId": "69f350f2-1f3c-488a-87e8-c5abb7018bad",
                "position": {
                    "id": "7ee66631-8236-4ad0-9b9f-5b7b3224db5b",
                    "x": 200,
                    "y": 450,
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "createdAt": "2024-08-05T18:49:22.108131Z",
                    "updatedAt": "2024-08-05T18:49:22.108131Z",
                    "userId": null
                },
                "dependencies": [],
                "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
                "pipelineVersionId": "06da5413-b0c5-4f26-9014-2d6c40ec382e",
                "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
                "createdAt": "2024-06-07T23:24:36.760389Z",
                "updatedAt": "2024-06-07T23:24:36.760389Z",
                "userId": null
            }, {
                "stepType": "outputStep",
                "stepId": "d8229e1f-8fe0-48c0-b375-9b73879f885a",
                "position": {
                    "id": "bf6fb588-82bf-43c3-996f-fc437b2a4abd",
                    "x": 997,
                    "y": 142,
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "createdAt": "2024-08-05T18:49:22.107906Z",
                    "updatedAt": "2024-08-05T18:49:22.107907Z",
                    "userId": null
                },
                "dependencies": ["08919212-59de-4ec5-a452-d6f6de4131f6"],
                "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
                "pipelineVersionId": "06da5413-b0c5-4f26-9014-2d6c40ec382e",
                "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
                "createdAt": "2024-06-07T23:24:36.769539Z",
                "updatedAt": "2024-06-07T23:29:11.965319Z",
                "userId": null
            }]
        },
        "versions": [{
            "id": "533abc28-0ea9-438f-bb85-178bc2ccf170",
            "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
            "versionNumber": 1,
            "isLatest": true,
            "steps": [{
                "modelId": "75698c6a-2408-4e62-a113-fd464e197242",
                "temperature": 0.5,
                "includeDateTimeContext": true,
                "promptId": "d3676ce1-e93c-4778-9fbe-b1a251e3edd0",
                "selectedPromptVersion": null,
                "stepType": "AIOperation",
                "stepId": "08919212-59de-4ec5-a452-d6f6de4131f6",
                "position": {
                    "id": "7e95c08c-6d50-496a-a73c-41d95516f8b1",
                    "x": 761,
                    "y": 257,
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "createdAt": "2024-08-05T18:49:22.108125Z",
                    "updatedAt": "2024-08-05T18:49:22.108125Z",
                    "userId": null
                },
                "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
                "pipelineVersionId": "06da5413-b0c5-4f26-9014-2d6c40ec382e",
                "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
                "createdAt": "2024-06-03T14:12:36.7039Z",
                "updatedAt": "2024-06-07T23:30:14.902254Z",
                "userId": null
            }, {
                "stepType": "inputStep",
                "stepId": "69f350f2-1f3c-488a-87e8-c5abb7018bad",
                "position": {
                    "id": "7ee66631-8236-4ad0-9b9f-5b7b3224db5b",
                    "x": 200,
                    "y": 450,
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "createdAt": "2024-08-05T18:49:22.108131Z",
                    "updatedAt": "2024-08-05T18:49:22.108131Z",
                    "userId": null
                },
                "dependencies": [],
                "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
                "pipelineVersionId": "06da5413-b0c5-4f26-9014-2d6c40ec382e",
                "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
                "createdAt": "2024-06-07T23:24:36.760389Z",
                "updatedAt": "2024-06-07T23:24:36.760389Z",
                "userId": null
            }, {
                "stepType": "outputStep",
                "stepId": "d8229e1f-8fe0-48c0-b375-9b73879f885a",
                "position": {
                    "id": "bf6fb588-82bf-43c3-996f-fc437b2a4abd",
                    "x": 997,
                    "y": 142,
                    "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                    "projectId": "00000000-0000-0000-0000-000000000000",
                    "createdAt": "2024-08-05T18:49:22.107906Z",
                    "updatedAt": "2024-08-05T18:49:22.107907Z",
                    "userId": null
                },
                "dependencies": ["08919212-59de-4ec5-a452-d6f6de4131f6"],
                "pipelineId": "b94338e8-269c-45b6-bf6d-2270d9bd041d",
                "pipelineVersionId": "06da5413-b0c5-4f26-9014-2d6c40ec382e",
                "tenantId": "2ce49ae0-c3ff-421a-91b7-830d0c73b348",
                "projectId": "49c66cc6-38db-4d71-967e-27a4a2b403c4",
                "createdAt": "2024-06-07T23:24:36.769539Z",
                "updatedAt": "2024-06-07T23:29:11.965319Z",
                "userId": null
            }]
        }],
        "executionStats": {"successCount": 0, "failureCount": 0}
    },
    userInput,
    "images": [],
    "temporaryPrompt": "",
    "saveHistory": false,
    "debug": true,
    "asyncOutput": false,
    "inMemoryMessages": [],
    "conversationId": "7d4c7d2e-adc4-44e7-801a-a59a45d7c1c9"
})

const createTemporaryPipeline = (
    conversationId: string,
    modelId: string,
    selectedDatasets: Set<string>,
    projectId: string,
    query: string
): TemporaryPipelineRequest => {
    const temporaryPipelineId = uuidv4()
    const tempVersion: PipelineVersion = {
        id: uuidv4(),
        pipelineId: temporaryPipelineId,
        versionNumber: 1,
        isLatest: true,
        steps: [
            {
                stepType: StepType.InputStep,
                stepId: uuidv4(),
                position: {x: 200, y: 450},
                dependencies: [],
                pipelineId: temporaryPipelineId,
            },
            // Data search steps will be inserted here
            {
                modelId,
                temperature: 1,
                prompt: null,
                selectedPromptVersion: null,
                stepType: StepType.AIOperation,
                stepId: uuidv4(),
                position: {x: 420, y: 450},
                dependencies: [],
                includeContext: false,
                pipelineId: temporaryPipelineId,
            },
            {
                stepType: StepType.OutputStep,
                stepId: uuidv4(),
                position: {x: 800, y: 450},
                dependencies: [],
                pipelineId: temporaryPipelineId,
            },
        ],
    }

    // Insert data search steps
    const dataSearchSteps: DatasetStepData[] = Array.from(selectedDatasets).map(
        (datasetId, index) => ({
            stepType: StepType.DataSearch,
            stepId: uuidv4(),
            position: {x: 300, y: 450 + index * 100},
            dependencies: [tempVersion.steps[0].stepId],
            pipelineId: temporaryPipelineId,
            dataSource: datasetId,
            topK: '10000',
            relevanceThreshold: '1',
            dataSourceType: DataSourceType.FILE_UPLOAD,
            databaseType: 'mongodb',
        })
    )

    // Insert data search steps after the input step
    tempVersion.steps.splice(1, 0, ...dataSearchSteps)

    // Update AI operation step dependencies
    const aiOperationStep = tempVersion.steps.find(
        (step) => step.stepType === StepType.AIOperation
    )
    if (aiOperationStep) {
        aiOperationStep.dependencies = [
            tempVersion.steps[0].stepId,
            ...dataSearchSteps.map((step) => step.stepId),
        ]
    }

    // Update output step dependencies
    const outputStep = tempVersion.steps[tempVersion.steps.length - 1]
    outputStep.dependencies = [aiOperationStep!.stepId]

    // Update positions based on the new steps
    tempVersion.steps.forEach((step, index) => {
        step.position.y = 450 + index * 100
    })

    return {
        pipeline: {
            id: temporaryPipelineId,
            description: 'Temporary Pipeline for Assistant',
            name: 'Temporary Assistant Pipeline',
            executionName: 'temporary_assistant_pipeline',
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            projectId: projectId,
            activeVersionId: tempVersion.id,
            activeVersion: tempVersion,
            versions: [tempVersion],
            executionStats: {
                successCount: 0,
                failureCount: 0,
            },
        },
        userId: '',
        userInput: query,
        conversationId,
        asyncOutput: true,
        debug: false,
    }
}
